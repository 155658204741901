import { dataProvider } from "data";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sales_pages_apis } from "./API";
import {
  SALES_PAGES__UPDATE_ALL_LIVE_SALES_PAGES_LIST,
  SALES_PAGES__UPDATE_SALES_PAGES_LISTS,
  SALES_PAGES__UPDATE_TEMPLATES_LIST,
  SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL,
} from "./Redux/actions";
import {
  ADD_VIDEO_OPTIONS,
  SALES_PAGE_UUID_PARAM,
  TEMPLATE_UUID_PARAM,
} from "./constants";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import { appendUrlParams, getRAUrlParams } from "utils/urlUtils";
import { useNotify } from "react-admin";
import { notification_color_keys } from "utils/hooks";
import ReactPlayer from "react-player";
import { is_empty, isHTMLString, validURLV2 } from "utils/validations";
import { isLoomVideoUrl } from "features/Common/modules/Urls&Routing/Urls&Routing.utils";
import { logError } from "utils/error";
import { sales_page_detail_keys } from "features/SalesPages/constants/SalesPages.constants";
import { apiMethods } from "data/api.constants";

export const getSelectedTemplateUuid = () => {
  const templateUuid = getRAUrlParams().get(TEMPLATE_UUID_PARAM);
  return `${templateUuid}`;
};

export const getSelectedSalesPageUuid = () => {
  const salesPageUuid = getRAUrlParams().get(SALES_PAGE_UUID_PARAM);
  return `${salesPageUuid}`;
};

export const useFetchSalesPageTemplatesList = () => {
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);
  const templatesList = useSelector((state) => state.salesPages.templatesList);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    if (is_empty(templatesList)) {
      setIsFetchingTemplates(true);
      dataProvider
        .custom_request(sales_pages_apis.get_sales_page_templates_list)
        .then((response) => {
          const templatesResponse = (response?.data?.exly_templates || []).map(
            (i) => ({
              ...i,
              background_image: i.background_image,
              color_palette: i.color_palette,
              desktop_preview_image: i.desktop_preview_image,
              mobile_preview_image: i.mobile_preview_image,
              slug: i.slug,
              template_name: i.template_name,
              uuid: i.uuid,
              previewPageUrl: i.preview_page_url,
            })
          );

          dispatch({
            type: SALES_PAGES__UPDATE_TEMPLATES_LIST,
            payload: templatesResponse,
          });
        })
        .catch((e) => {
          notify(
            "An error occured. Please try again after some time",
            notification_color_keys.error
          );
          console.error(
            "error occured while calling get_sales_page_templates_list",
            e
          );
        })
        .finally(() => {
          setIsFetchingTemplates(false);
        });
    }
  }, []);

  return { templatesList, isFetchingTemplates };
};

/**
 * NOTE: dont pick salePageUuid from salePageDetail itself, it might be stale
 */
export const useFetchSelectedSalePageDetail = ({
  salePageUuid: salePageUuidArg,
  fetchSeoDetails,
  getListingData,
}) => {
  const dispatch = useDispatch();
  const salePageDetail = useSelector(
    (state) => state.salesPages.selectedSalesPage
  );

  const [isFetching, setIsFetching] = useState(false);

  const updateSelectedSalePageDetails = (newSalePageDetail) => {
    dispatch({
      type: SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL,
      payload: {
        ...newSalePageDetail,
        uuid: salePageUuidArg, // so that uuid is not overridden
      },
    });
  };

  useEffect(() => {
    if (!salePageUuidArg) return;

    setIsFetching(true);
    dataProvider
      .custom_request(
        appendUrlParams(
          `${sales_pages_apis.fetch_section_detail}/${salePageUuidArg}`,
          {
            fetch_seo_details: fetchSeoDetails,
            listing_data: getListingData,
          }
        ),
        apiMethods.GET
      )
      .then((response) => {
        const salePageDetail = {
          ...response?.data?.sales_page,
          uuid: salePageUuidArg,

          // NOTE: `listing_uid` key is deprecated.
          // Why: Earlier, api was sending `listing_uid` instead of `listing_uuid`.
          //      This is done so that flow does not break in places that are still using listing_uid.
          listing_uid:
            response?.data?.sales_page[sales_page_detail_keys.listing_uuid],
        };
        dispatch({
          type: SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL,
          payload: salePageDetail,
        });
      })
      .catch((error) => {
        logError({
          error,
          occuredAt: "src/ui/pages/SalesPages/utils.js",
          when: "calling fetch sales page details",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [salePageUuidArg]);

  // State cleanup on component is unmount
  React.useEffect(() => {
    return () => {
      dispatch({
        type: SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL,
        payload: {},
      });
    };
  }, []);

  return {
    salePageDetail,
    isFetching,
    isDataStale:
      salePageUuidArg &&
      salePageUuidArg !== salePageDetail?.[sales_page_detail_keys.uuid],
    updateSelectedSalePageDetails,
  };
};

export const useSalesPagesList = ({ params = {}, forceFetch = true }) => {
  let paramsKey = undefined;
  try {
    paramsKey = JSON.stringify(params);
  } catch {
    // paramsKey is undefined
    // do nothing in this case
  }

  const salesPagesList = useSelector((state) =>
    paramsKey ? state.salesPages.salesPagesLists[paramsKey] : undefined
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!paramsKey) return;
    if (salesPagesList && !forceFetch) return;

    dataProvider
      .custom_request(sales_pages_apis.get_sales_pages_list, "GET", params)
      .then((response) => {
        const responseData = response?.data || {};

        dispatch({
          type: SALES_PAGES__UPDATE_SALES_PAGES_LISTS,
          payload: { [paramsKey]: responseData },
        });
      })
      .catch(() => {
        // do nothing
      });
  }, [paramsKey]);

  return { salesPagesList };
};

/**
 * @deprecated use `useSalesPagesList` instead
 */
export const useFetchAllSalesPagesOfLiveOfferings = () => {
  const allLiveSalesPagesList = useSelector(
    (state) => state.salesPages.allLiveSalesPagesList
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dataProvider
      .custom_request(sales_pages_apis.get_sales_pages_list, "GET", {
        page: "all",
        status: offering_availability_statuses.live,
      })
      .then((response) => {
        const salesPagesResponse = response?.data?.sales_pages || [];

        dispatch({
          type: SALES_PAGES__UPDATE_ALL_LIVE_SALES_PAGES_LIST,
          payload: salesPagesResponse.map((i) => ({
            ...i,
          })),
        });
      })
      .catch(() => {
        // do nothing
      });
  }, []);

  return { allLiveSalesPagesList };
};

/**
 * check string first isHtml and if not converting in to html
 * @param {*} param0
 * @returns
 */
export const convertStringToHtml = ({ stringToConvert }) => {
  const htmlString = `<p>${stringToConvert || ""}</p>`;

  return isHTMLString(stringToConvert) ? stringToConvert : htmlString;
};

/**
 * handling of fetching tab Assets data from nested array coming from backend
 * @param {object} data
 * @returns title and favicon
 */
export const getTabAssestsTitleAndIcon = (data) => {
  const fetchTabAssetsData = data?.template_appearance_data?.find((item) =>
    Object.prototype.hasOwnProperty.call(item, "TabAssets")
  )?.TabAssets;
  const fetchData = fetchTabAssetsData?.[0]?.data?.section_data;
  const title = fetchData?.title || "";
  const fav_icon = fetchData?.fav_icon || "";
  return { title, fav_icon };
};

/**
 * This will generate a letter favicon with exly's design language by as default
 */
export const getAvatarIcon = ({
  text = "",
  bgColour = "6c5dd3",
  textColor = "fff",
  font = "",
  fontSize = "28",
}) =>
  is_empty(text)
    ? undefined
    : `https://fakeimg.pl/32/${bgColour}/${textColor}/?text=${text[0]}&font=${font}&font_size=${fontSize}`;

export const getPlaceholderImageUrl = ({
  width = 400,
  height = 400,
  format = "png",
  text = "Upload",
  textColor = "000000",
  bgColor = "FFFFFF",
}) => {
  return `https://placehold.co/${width}x${height}/${bgColor}/${textColor}/${format}/?text=${encodeURIComponent(
    text
  )}`;
};

/**
 * @description recommended format text generator
 * @param {*} x number in pixels
 * @param {*} y number in pixels
 * @returns string for the recommended format filled in this format
 * `Recommended format: ${x} X ${y} pixels (PNG or JPG)`
 */
export const imgRecommendedTextGen = (x, y) =>
  `Recommended format: ${x} X ${y} pixels (PNG or JPG)`;

/**
 * Checks if the provided URL is valid for embedding, based on upload type and URL compatibility.
 * @param {Object} options - Object containing the URL and upload type.
 * @param {string | undefined} options.url - The URL to check for embedding validity.
 * @param {string} options.uploadType - The type of upload (e.g., URL, video upload).
 * @returns {boolean} - Returns true if the URL is valid for embedding, false otherwise.
 */
export const checkIsValidUrlEmbedded = ({ url, uploadType }) => {
  return (
    !is_empty(url) &&
    !ReactPlayer.canPlay(url) &&
    uploadType !== ADD_VIDEO_OPTIONS.UPLOAD_VIDEO.slug
  );
};

export const checkIsPlayableVideoUrl = ({ url, uploadType }) => {
  return (
    (uploadType === ADD_VIDEO_OPTIONS.ADD_LINK.slug &&
      validURLV2(url) &&
      (ReactPlayer.canPlay(url) || isLoomVideoUrl(url))) ||
    //
    // assuming the upload service is giving this upload url AND its a correct value
    // TODO: add better check on services where we upload the URL
    (uploadType === ADD_VIDEO_OPTIONS.UPLOAD_VIDEO.slug && validURLV2(url))
  );
};

export const checkIsWistiaEmbedCode = (htmlString = "") => {
  return (
    htmlString.includes("https://fast.wistia.com/embed/") ||
    htmlString.includes("https://fast.wistia.net/embed/") ||
    htmlString.includes("https://embedwistia-a.akamaihd.net/deliveries/")
  );
};
